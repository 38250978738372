<template>
  <div class="main m-l">
    <div class="cl">
      <h1 class="fl h1">{{$t('BasicInformation')}}</h1>
      <router-link class="fl btn-upgrade" :to="{name:'AdminMember'}">{{$t('Upgrade now')}}</router-link>
    </div>
    <tabs v-model="activeName">
      <tab-pane :label="$t('Company Info')" name="basicInformation">
        <basic-information/>
      </tab-pane>
      <tab-pane :label="$t('ContactInformation')" name="contactInformation">
        <contact-information/>
      </tab-pane>
    </tabs>
  </div>
</template>
<script>
  import Tabs from '../../../components/Tabs/Tabs';
  import TabPane from '../../../components/Tabs/TabPane';
  import BasicInformation from './components/BasicInformation';
  import ContactInformation from './components/ContactInformation';

  export default {
    name: 'AdminMy',
    data() {
      return {
        activeName: ''
      };
    },
    components: {Tabs, TabPane, BasicInformation, ContactInformation},
    mounted() {
      this.activeName = this.$route.query.current || 'basicInformation';
    },
    beforeRouteUpdate(to, from, next) {
      this.activeName = to.query.current || 'basicInformation';
      next();
    }
  };
</script>
<style scoped lang="less">
  .main{
    .h1{font-size:24px;}
    .btn-upgrade{width:132px;margin-left:16px;line-height:30px;text-align:center;color:#ff6f61;font-size:16px;border:1px solid #ff6f61;border-radius:4px;}
    /deep/ .select{
      width:330px;vertical-align:top;
      .el-input__inner{font-size:16px;}
      &.w1{width:120px;}
    }
  }
</style>
