<template>
  <div class="form-wrap">
    <table class="table">
      <tr>
        <td class="label">{{$t('Appellation')}}：</td>
        <td>
          <el-select class="select" v-model="gender" size="small" placeholder="">
            <el-option v-for="item in genderOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </td>
      </tr>
      <tr>
        <td class="label">{{$t('Contact Name')}}：</td>
        <td>
          <input class="ipt" type="text" maxlength="100" autocomplete="off" v-model.trim="userName"/>
        </td>
      </tr>
      <tr>
        <td class="label">{{$t('Position')}}：</td>
        <td>
          <input class="ipt" type="text" maxlength="100" autocomplete="off" v-model.trim="position"/>
        </td>
      </tr>
      <tr>
        <td class="label">{{$t('Email')}}：</td>
        <td>
          <input class="ipt" type="text" maxlength="100" autocomplete="off" v-model.trim="userEmail"/>
        </td>
      </tr>
      <tr>
        <td class="label">{{$t('Contact Number')}}：</td>
        <td>
          <el-select class="select w1 m-r" size="small" :placeholder="$t('Area code')" v-model="phonePrefix">
            <el-option v-for="item in areaCodeOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
          <input class="ipt w1" type="text" maxlength="100" autocomplete="off" v-model.trim="userPhone"/>
        </td>
      </tr>
      <!--其他聯絡方式-->
      <tr>
        <td class="label">{{$t('Other Contact')}}({{$t('Optional')}})：</td>
        <td>
          <div class="cl" style="margin-bottom:10px;" v-for="(item,index) of otherContact" :key="index">
            <el-select class="select w1 m-r" size="small" :placeholder="$t('PleaseChoose')" v-model="item.phoneType">
              <el-option v-for="item in phoneTypeOptions"
                         :key="item.code"
                         :label="item.name"
                         :value="item.code">
              </el-option>
            </el-select>
            <el-select class="select w1 m-r" size="small" :placeholder="$t('Area code')" v-model="item.phonePrefix"
                       v-if="showPhoneType(item.phoneType)">
              <el-option v-for="item in areaCodeOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.code">
              </el-option>
            </el-select>
            <input class="ipt w1 m-r" :type="showPhoneType(item.phoneType)?'number':'text'"
                   autocomplete="off"
                   :placeholder="$t('PleaseEnter')"
                   v-model="item.userPhone"/>
            <!--删除聯絡方式-->
            <a class="btn-del" @click="delContact(index)">
              <img src="../../../../assets/images/icon/ashcan-red.png"/>
            </a>
          </div>
          <div>
            <!--添加聯絡方式-->
            <a class="btn-add" @click="addContact">
              <img class="vam" src="../../../../assets/images/icon/plus-circle-red.png"/>
              <span class="vam"> {{$t('Add contact')}}</span>
            </a>
          </div>
        </td>
      </tr>
      <!--語言偏好-->
      <!--<tr>
        <td class="label">{{$t('Language preference')}}：</td>
        <td>
          <el-select class="select" v-model="language" size="small" placeholder="">
            <el-option v-for="item in languageOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </td>
      </tr>-->
      <tr>
        <td></td>
        <td>
          <!--<button class="button m-r" type="button">{{$t('Cancel')}}</button>-->
          <button class="button button-primary" type="button" @click="onSubmit">{{$t('Save')}}</button>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
  import {isEmail} from '../../../../utils/validate';
  import {getDictList} from '../../../../api/admin-common';
  import {getContactInfo, updateContactInfo} from '../../../../api/admin-user';
  import {requiredIf} from 'vuelidate/lib/validators';
  import {scrollToY} from '@/utils';
  import Vue from 'vue';
  import Vuelidate from 'vuelidate';
  Vue.use(Vuelidate);
  export default {
    name: 'ContactInformation',
    data() {
      return {
        genderOptions: [],
        gender: '',
        languageOptions: [],
        language: '',
        userName: '',
        position: '',
        userEmail: '',
        areaCodeOptions: [],
        phonePrefix: '',
        userPhone: '',
        otherContact: [],
        phoneTypeOptions: []
      };
    },
    validations: {
      otherContact: {
        $each: {
          phoneType: {
            required: requiredIf((datas) => {
              return datas.userPhone !== '';
            })
          },
          phonePrefix: {
            required: requiredIf((datas) => {
              return ['shoutidianhua', 'Phone', 'Fax'].includes(datas.phoneType);
            })
          },
          userPhone: {
            required: requiredIf((datas) => {
              return datas.phoneType !== '';
            })
          }
        }
      }
    },
    created() {
      this.getDicts();
      this.getContactInfo();
    },
    methods: {
      // 获取数据字典
      getDicts() {
        getDictList('appellative').then(res => {
        });
        getDictList('phonePrefix').then(res => {
          this.areaCodeOptions = res.value;
        });
        getDictList('systemLanguage').then(res => {
          this.languageOptions = res.value || [];
        });
        // 获取称谓选项
        getDictList('appellative').then(res => {
          this.genderOptions = res.value || [];
        });
        // 获取联系方式类型
        getDictList('phoneType').then(res => {
          this.phoneTypeOptions = res.value || [];
        });
      },
      // 添加聯絡方式
      addContact() {
        this.otherContact.push({phoneType: '', phonePrefix: '', userPhone: ''});
      },
      // 删除联络方式
      delContact(index) {
        this.otherContact.splice(index, 1);
        this.$nextTick(() => {
          if (!this.otherContact.length) {
            this.addContact();
          }
        });
      },
      // 获取联络人信息
      getContactInfo() {
        getContactInfo().then(res => {
          const list = res.value || [];
          const {userName, isCalled, position, userEmail, phonePrefix, userPhone, languagePreference} = list.find(item => item.contactType === 'Y') || {};
          this.otherContact = list.filter(item => item.contactType !== 'Y');
          this.userName = userName;
          this.gender = isCalled;
          this.position = position;
          this.userEmail = userEmail;
          this.phonePrefix = phonePrefix;
          this.userPhone = userPhone;
          this.language = languagePreference;

          if (!this.otherContact.length) {
            this.addContact();
          }
        });
      },
      // 修改联络人信息
      onSubmit() {
        if (this.$v.$invalid) {
          this.$message.warning(this.$t('Please complete the information before submitting'));
          this.$nextTick(() => {
            if (document.querySelector('.is-error')) {
              scrollToY(undefined, document.querySelector('.is-error').offsetTop);
            }
          });
          return false;
        }
        let userContacts = [];
        const data = {
          contactType: 'Y',
          isCalled: this.gender,
          userName: this.userName,
          position: this.position,
          userEmail: this.userEmail,
          phoneType: '1',
          phonePrefix: this.phonePrefix,
          userPhone: this.userPhone,
          languagePreference: this.language || 'zw'
        };

        if (Object.values(data).filter(item => !item).length) {
          this.$message.warning('请先完善資料');
          return false;
        }
        if (!isEmail(data.userEmail)) {
          this.$message.warning(this.$t('PleaseEnterTheCorrectEmail'));
          return false;
        }

        userContacts.push(data);
        for (let item of this.otherContact) {
          if (item.phoneType && item.userPhone) {
            userContacts.push({
              contactType: 'N',
              userName: '',
              isCalled: '',
              position: '',
              phoneType: item.phoneType,
              phonePrefix: this.showPhoneType(item.phoneType) ? item.phonePrefix : '',
              userPhone: item.userPhone,
              userEmail: '',
              languagePreference: ''
            });
          }
        }

        updateContactInfo(userContacts).then(res => {
          this.$message.success(this.$t('SaveSuccessfully'));
        });
      },
      showPhoneType(phoneType) {
        return ['shoutidianhua', 'Phone', 'Fax'].includes(phoneType);
      }
    }
  };
</script>
<style scoped lang="less">
  .form-wrap{
    margin-top:18px;
    .table{
      width:100%;
      td{padding:12px 0;}
    }
    .label{width:112px;line-height:34px;white-space:nowrap;font-size:16px;}
    .ipt{
      width:330px;height:32px;padding:0 10px;color:#321908;font-size:16px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
      &.w1{width:200px;}
    }
    .btn-add{display:inline-block;color:#ff6f61;}
    .btn-del{display:inline-block;padding:6px 0;font-size:0;}
    .m-r{margin-right:10px;}
  }
</style>
