<template>
  <div class="form-wrap">
    <table class="table">
      <!--公司名稱-->
      <tr>
        <td class="label">{{$t('Company Name')}}：</td>
        <td>
          <input class="ipt" type="text" maxlength="100" autocomplete="off" v-model="companyName"/>
        </td>
      </tr>
      <!--法定公司名稱-->
      <tr>
        <td class="label">{{$t('French Company Name')}}：</td>
        <td>
          <input class="ipt" type="text" maxlength="100" autocomplete="off" disabled="disabled"
                 v-model="legalCompanyName"/>
        </td>
      </tr>
      <!--商戶等級-->
      <tr>
        <td class="label">{{$t('Business Level')}}：</td>
        <td>
          <input class="ipt" type="text" maxlength="100" autocomplete="off" disabled="disabled" v-model="memberType" />
          <router-link class="btn-renewalfee" :to="{name:'AdminMember'}">
            {{$t('Upgrade')}}/{{$t('Renewal')}}
          </router-link>
        </td>
      </tr>
      <!--國家/地區-->
      <tr>
        <td class="label">{{$t('Country')}}/{{$t('Region')}}：</td>
        <td>
          <el-select class="select" filterable size="small" placeholder="" @change="countryChange" v-model="country">
            <el-option v-for="item in countryOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </td>
      </tr>
      <!--省/州-->
      <tr>
        <td class="label">{{$t('Province State')}}：</td>
        <td>
          <el-select class="select" filterable size="small" placeholder=""
                     :disabled="!!country&&!provinceOptions.length"
                     @change="provinceChange"
                     v-model="province">
            <el-option v-for="item in provinceOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </td>
      </tr>
      <!--城市-->
      <tr>
        <td class="label">{{$t('City')}}：</td>
        <td>
          <el-select class="select" filterable size="small" placeholder="" v-model="city">
            <el-option v-for="item in cityOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </td>
      </tr>
      <!--地址-->
      <tr>
        <td class="label">{{$t('Address')}}：</td>
        <td>
          <input class="ipt" type="text" maxlength="100" autocomplete="off" v-model.trim="address"/>
        </td>
      </tr>
      <!--聯絡電話-->
      <tr>
        <td class="label">{{$t('Contact Number')}}：</td>
        <td>
          <el-select class="select w1" size="small" :placeholder="$t('Area code')" v-model="areaCode">
            <el-option v-for="item in areaCodeOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
          <input class="ipt" style="width:200px;margin-left:10px;" type="text" maxlength="100" autocomplete="off"
                 v-model.trim="phone"/>
        </td>
      </tr>
      <!--Email-->
      <tr>
        <td class="label">{{$t('Email')}}：</td>
        <td>
          <input class="ipt" type="text" maxlength="100" autocomplete="off" v-model.trim="email"/>
        </td>
      </tr>
      <tr>
        <td class="label">{{$t('Company Profile')}}：</td>
        <td>
          <el-input type="textarea"
                    placeholder=""
                    v-model="introduction"
                    maxlength="1000"
                    show-word-limit>
          </el-input>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          <!--<button class="button m-r" type="button">{{$t('Cancel')}}</button>-->
          <button class="button button-primary" type="button" @click="onSubmit">{{$t('Save')}}</button>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
  import {isEmail} from '../../../../utils/validate';
  import {getAdminInfo, updateAdminInfo} from '../../../../api/admin-user';
  import {getMemberTypeList} from '../../../../api/admin-member';
  import {getDictList, getProvinceList, getCitys} from '../../../../api/admin-common';

  export default {
    name: 'BasicInformation',
    data() {
      return {
        enterpriseCode: '',
        companyName: '',
        legalCompanyName: '',
        memberType: '',
        countryOptions: [],
        country: '',
        provinceOptions: [],
        province: '',
        cityOptions: [],
        city: '',
        address: '',
        areaCodeOptions: [],
        areaCode: '',
        phone: '',
        email: '',
        introduction: ''
      };
    },
    created() {
      this.getDicts();
      this.getMyInfo();
    },
    methods: {
      // 获取数据字典
      getDicts() {
        getDictList('country').then(res => {
          this.countryOptions = res.value;
        });
        getDictList('phonePrefix').then(res => {
          this.areaCodeOptions = res.value;
        });
      },
      // 获取我的信息
      getMyInfo() {
        getAdminInfo().then(res => {
          const {enterpriseCode, companyName, legalCompanyName, country, province, city, address, phoneType, phone, email, introduction} = res.value || {};
          this.enterpriseCode = enterpriseCode;
          this.companyName = companyName;
          this.legalCompanyName = legalCompanyName;
          this.country = country;
          this.province = province || '';
          this.city = city;
          this.address = address;
          this.areaCode = phoneType;
          this.phone = phone;
          this.email = email;
          this.introduction = introduction;

          if (this.country) {
            this.getProvinceList(this.country);
            this.getCityList();
          }
        });
        getMemberTypeList().then(res => {
          const {currentMemberTypeId, memberTypes} = res.value || {};
          let displayMemberType = memberTypes.filter(t => t.id === currentMemberTypeId)[0].memberTypeName;
          this.memberType = displayMemberType;
        });
      },
      // 修改
      onSubmit() {
        let data = {
          enterpriseCode: this.enterpriseCode,
          companyName: this.companyName,
          legalCompanyName: this.legalCompanyName,
          country: this.country,
          city: this.city,
          address: this.address,
          phoneType: this.areaCode,
          phone: this.phone,
          email: this.email,
          introduction: this.introduction
        };

        if (Object.values(data).filter(item => !item).length) {
          this.$message.warning('请先完善資料');
          return false;
        }
        if (!isEmail(data.email)) {
          this.$message.warning(this.$t('PleaseEnterTheCorrectEmail'));
          return false;
        }

        data.province = this.province;
        updateAdminInfo(data).then(res => {
          this.$message.success(this.$t('SaveSuccessfully'));
        });
      },
      countryChange(value) {
        // 重新获取省、州相关数据
        this.provinceOptions = [];
        this.province = '';
        this.getProvinceList(value);
        // 重新获取城市相关数据
        this.provinceChange();
      },
      provinceChange(value) {
        this.cityOptions = [];
        this.city = '';
        this.getCityList();
      },
      // 获取省列表
      getProvinceList(value) {
        getProvinceList(value).then(res => {
          this.provinceOptions = res.value || [];
        });
      },
      // 获取城市列表
      getCityList() {
        // 获取城市字典
        getCitys(this.country, this.province).then(res => {
          this.cityOptions = res.value || [];
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .form-wrap{
    margin-top:18px;
    .table{
      width:100%;
      td{padding:12px 0;}
    }
    .label{width:112px;line-height:34px;white-space:nowrap;font-size:16px;}
    .ipt{
      width:330px;height:32px;padding:0 10px;color:#321908;font-size:16px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
      &[disabled]{border-color:#f4f4f4;background-color:#f4f4f4;}
    }
    /deep/ .el-textarea__inner{height:200px;padding:10px;}
    .btn-renewalfee{display:inline-block;margin-left:16px;line-height:32px;color:#ff6f61;font-size:16px;}
  }
</style>
