import request from '@/utils/request';
import {getLocale} from '../utils/language';

const language = getLocale() || 'zh-hk';

// 获取会员类型列表
export function getMemberTypeList() {
  return request({
    url: `/admin/member/type/list/${language}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 会员购买
export function memberBuy(data) {
  return request({
    url: '/admin/member/buy',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 查询订单支付状态
export function getMemberPayStatus(orderId) {
  return request({
    url: `/admin/member/pay/status/${orderId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 查询购买记录列表（分页）
export function getMemberOrderList(data) {
  return request({
    url: '/admin/member/order/list',
    baseURL: '/v2',
    method: 'get',
    params: data
  });
}

// paypal支付
export function verifyPaypal(payPalOrderId) {
  return request({
    url: `/admin/pay/verify/paypal/${payPalOrderId}`,
    baseURL: '/v2',
    method: 'get'
  });
}
